import { sortBy } from "lodash";
import debounce from "lodash/debounce";
import moment from "moment";
import { X } from "phosphor-react";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import React, { useContext, useEffect, useState } from "react";
import { FeatureToggleContext } from "../../context/FeatureToggleContext";
import { isFeatureEnabled } from "../../functions/consult/isFeatureEnabled";
import { getMyPrograms } from "../../functions/getConsults";
import { FeatureNameEnum } from "../../hooks/useFeatureToggle";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ConsultCardFilterProps } from "../../types/components/queue/consultCardFilterProps";
import { ConsultReasonForVisitEnum, Program } from "../../types/consult-api";
import { CountdownTimer } from "../CountdownTimer";

const debouncedFetch = debounce((fn, value) => fn(value), 800);

const ConsultCardFilter = (props: ConsultCardFilterProps) => {
    const [ls, setLS] = useLocalStorage("countdownTimer", { expiresAt: "" });
    const [expiresAt, setExpiresAt] = useState(ls.expiresAt);
    const [isAsyncConsultsOnly, setIsAsyncConsultsOnly] = useState(!!ls.expiresAt);
    const [isFiltered, setIsFiltered] = useState(false);
    const [partners, setPartners] = useState<string[]>([]);
    const [programs, setPrograms] = useState<string[]>([]);
    const [reasons, setReasons] = useState<ConsultReasonForVisitEnum[]>([]);
    const [isReasonForVisitSideEffectOnly, setIsReasonForVisitSideEffectOnly] = useState(false);
    const { features } = useContext(FeatureToggleContext);

    const [partnerOptions, setPartnerOptions] = useState<any[]>([]);
    const [programOptions, setProgramOptions] = useState<any[]>();
    useEffect(() => {
        getMyPrograms().then((result) => {
            const filteredPrograms = result.filter((program: Program) => !program.partnerName.includes("Sandbox") && !program.name.includes("Sandbox"));

            const partners = (() => {
                const partners: any = {};
                filteredPrograms.map((program) => {
                    if (!partners[program.partnerGuid]) {
                        partners[program.partnerGuid] = {
                            label: program.partnerName,
                            value: program.partnerGuid,
                        };
                    }
                });
                return Object.values(partners);
            })();

            const programs = filteredPrograms.map((program: Program) => ({
                label: program.name,
                value: program.guid,
            }));

            setPartnerOptions([...sortBy(partners, "label")]);
            setProgramOptions([...sortBy(programs, "label")]);
        });
    }, []);

    useEffect(() => {
        console.log("setIsFiltered");
        setIsFiltered(isAsyncConsultsOnly || partners.length > 0 || programs.length > 0 || reasons.length > 0);
        if (isAsyncConsultsOnly) {
            // Handle the case where the async consults only filter is true by default
            props.methods.onIsAsyncConsultsOnlyChange(true);
        }
    }, [isAsyncConsultsOnly, partners, programs, reasons]);

    const _onIsAsyncConsultsOnlyChange = (e: InputSwitchChangeEvent) => {
        const value = e.value;
        setIsAsyncConsultsOnly(value);
        props.methods.onIsAsyncConsultsOnlyChange(value);

        const expiresAt = value ? moment().add(12, "minutes").toISOString() : "";
        setExpiresAt(expiresAt);
        setLS({ expiresAt });
    };

    const _onPartnerChange = (e: MultiSelectChangeEvent) => {
        const value = e.value;
        setPartners(value);
        debouncedFetch(props.methods.onPartnerChange, value);
    };

    const _onProgramChange = (e: MultiSelectChangeEvent) => {
        const value = e.value;
        setPrograms(value);
        debouncedFetch(props.methods.onProgramChange, value);
    };

    const _onIsReasonForVisitSideEffectOnlyChange = (e: InputSwitchChangeEvent) => {
        const value = e.value;
        setIsReasonForVisitSideEffectOnly(value);
        if (value) {
            setReasons([ConsultReasonForVisitEnum.SideEffect]);
            props.methods.onReasonForVisitChange([ConsultReasonForVisitEnum.SideEffect]);
        } else {
            setReasons([]);
            props.methods.onReasonForVisitChange([]);
        }
    };

    const onExpire = function () {
        setIsAsyncConsultsOnly(false);
        props.methods.onIsAsyncConsultsOnlyChange(false);

        setExpiresAt("");
        setLS({ expiresAt: "" });
    };

    const clearFilter = () => {
        setIsAsyncConsultsOnly(false);
        props.methods.onIsAsyncConsultsOnlyChange(undefined);

        setPartners([]);
        props.methods.onPartnerChange(undefined);

        setPrograms([]);
        props.methods.onProgramChange(undefined);

        setReasons([]);
        props.methods.onReasonForVisitChange(undefined);

        setIsReasonForVisitSideEffectOnly(false);
        setIsFiltered(false);

        setExpiresAt("");
        setLS({ expiresAt: "" });
    };

    const removePartner = (partnerName: string) => {
        const value = partners.filter((p) => p !== partnerName);
        setPartners(value);
        props.methods.onPartnerChange(value);
    };

    const removeProgram = (programGuid: string) => {
        const value = programs.filter((p) => p !== programGuid);
        setPrograms(value);
        props.methods.onProgramChange(value);
    };

    return (
        <>
            {isFeatureEnabled(features, FeatureNameEnum.FilterByProgram) && (
                <>
                    <MultiSelect
                        value={partners}
                        options={partnerOptions}
                        onChange={_onPartnerChange}
                        placeholder="Filter by Partners"
                        className="w-full partners-filter"
                        maxSelectedLabels={1}
                        filter
                    />
                    <MultiSelect
                        value={programs}
                        options={programOptions}
                        onChange={_onProgramChange}
                        placeholder="Filter by Programs"
                        className="w-full programs-filter"
                        maxSelectedLabels={1}
                        filter
                    />
                </>
            )}
            {isFeatureEnabled(features, FeatureNameEnum.FilterByReasonForVisit) && (
                <div className={"side-effects-switch"}>
                    <InputSwitch checked={isReasonForVisitSideEffectOnly} onChange={_onIsReasonForVisitSideEffectOnlyChange} />
                    <p className={"text-sm font-normal"}>Side Effect Consults Only</p>
                </div>
            )}
            {isFeatureEnabled(features, FeatureNameEnum.FilterByAsyncConsultsOnly) && (
                <div className={"async-consults-switch"}>
                    <InputSwitch checked={isAsyncConsultsOnly} onChange={_onIsAsyncConsultsOnlyChange} />
                    <p className={"text-sm font-normal"}>Async Consults Only</p>
                    {isFeatureEnabled(features, FeatureNameEnum.NextConsultModal) && <CountdownTimer expiresAt={expiresAt} onExpire={onExpire} />}
                </div>
            )}
            {isFiltered && (
                <div className="filter-notification">
                    <span className={"filter-notification-text"}>
                        <p className="text-sm font-normal">Your queue is being filtered.</p>
                        <span className="text-sm clear-queue-filter-button" onClick={clearFilter}>
                            Show all consults?
                        </span>
                    </span>
                    {partners.length > 0 && (
                        <div className="text-sm font-semibold filtered-partners-list">
                            Partners:
                            {partners.map((partnerGuid) => {
                                const partner = partnerOptions?.find((p) => p.value === partnerGuid);
                                return (
                                    <p key={partnerGuid} className="text-sm font-normal filtered-item">
                                        <span className="filtered-icon">
                                            <X size={18} onClick={() => removePartner(partnerGuid)} />
                                        </span>
                                        <span className="filtered-label">{partner?.label}</span>
                                    </p>
                                );
                            })}
                        </div>
                    )}
                    {programs.length > 0 && (
                        <div className="text-sm font-semibold filtered-programs-list">
                            Programs:
                            {programs.map((programGuid) => {
                                const program = programOptions?.find((p) => p.value === programGuid);
                                return (
                                    <p key={programGuid} className="text-sm font-normal filtered-item">
                                        <span className="filtered-icon">
                                            <X size={18} onClick={() => removeProgram(programGuid)} />
                                        </span>
                                        <span className="filtered-label">{program?.label}</span>
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ConsultCardFilter;
