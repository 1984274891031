import { useEffect, useState } from "react";
import { logger } from "../logger/logger";

const getLS = function (key: string) {
    if (typeof window === "undefined") {
        return;
    }
    const item = localStorage.getItem(key);
    if (!item) {
        return;
    }
    try {
        return JSON.parse(item);
    } catch (err) {
        logger.error(err);
    }
};

const setLS = function (key: string, value: string) {
    if (typeof window === "undefined") {
        return;
    }
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
        logger.error(err);
    }
};

export const useLocalStorage = (key: string, defaultValue: any) => {
    const [item, setItem] = useState(getLS(key) || defaultValue);

    useEffect(() => {
        setLS(key, item);
    }, [key, item]);

    return [item, setItem];
};
