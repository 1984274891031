import useIntervalWhen from "@rooks/use-interval-when";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";

export interface CountdownTimerProps {
    expiresAt: string;
    onExpire?: () => void;
}

const calculateTimer = function (expiresAt: moment.Moment) {
    const now = moment();
    if (expiresAt <= now) {
        return "(0:00)";
    }
    const minutes = expiresAt.diff(now, "minutes");
    const seconds = `${expiresAt.diff(now, "seconds") % 60}`.padStart(2, "0");
    return `(${minutes}:${seconds})`;
};

export const CountdownTimer = (props: CountdownTimerProps) => {
    if (!props.expiresAt) {
        return <></>;
    }

    const expiresAt = useRef(moment(props.expiresAt));
    const [countdown, setCountdown] = useState(calculateTimer(expiresAt.current));
    const [timerEnabled, setTimerEnabled] = useState(expiresAt.current > moment());

    useEffect(() => {
        if (moment() > expiresAt.current) {
            if (props.onExpire) {
                props.onExpire();
            }
        }
    }, [!timerEnabled]);

    const checkTimer = function () {
        if (moment() > expiresAt.current) {
            setTimerEnabled(false);
        }
        setCountdown(calculateTimer(expiresAt.current));
    };
    useIntervalWhen(checkTimer, 1000, timerEnabled);

    return <span className="async-consults-countdown-timer">{countdown}</span>;
};
