import useIntervalWhen from "@rooks/use-interval-when";
import { CaretDoubleUp, CaretUp, Equals } from "phosphor-react";
import { Badge } from "primereact/badge";
import { Card } from "primereact/card";
import { Chip, ChipProps } from "primereact/chip";
import { Divider } from "primereact/divider";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ConsultCardButton, ConsultCardHeader, ConsultCardIcons, ConsultCardPatientInformation } from ".";
import { create as createConsultCardButtonProps } from "../../types/components/queue/consultCardButtonProps";
import { create as createConsultCardIconsProps } from "../../types/components/queue/consultCardIconsProps";
import { ConsultCardProps } from "../../types/components/queue/consultCardProps";
import { getVisitReason } from "../../utils";
import { getConsultCardPriority } from "../../utils/";
import { ConsultCardPriorityEnum } from "../../utils/getConsultCardPriority";
import ConsultCardCareTeamTaskInformation from "./ConsultCardCareTeamTaskInformation";

interface HollowChipProps extends ChipProps {
    color: string;
}

const HollowChip = styled(Chip)<HollowChipProps>`
    color: ${(props) => props.color};
    background-color: white;
    border: 1px solid ${(props) => props.color};
    margin-right: 0.5rem;
    white-space: nowrap;
    .p-chip-text {
        margin: 0;
        font-size: 0.875rem !important;
    }
`;

const ConsultCard = (props: ConsultCardProps) => {
    const [priority, setPriority] = useState({ textColor: "#ffffff", text: "", symbolColor: "", value: ConsultCardPriorityEnum.Normal });

    useEffect(() => {
        try {
            setPriority(getConsultCardPriority(props.consult, props.isDueNow));
        } catch (err) {}
    }, []);

    useIntervalWhen(
        () => {
            setPriority(getConsultCardPriority(props.consult, props.isDueNow));
        },
        10000,
        true
    );

    return (
        <div className={"consult-card"}>
            <ConsultCardCareTeamTaskInformation episodeGuid={props.consult.episodeGuid} consult={props.consult} />
            <Card className={`${props.methods.setUnavailableCss()}`} style={{ borderRadius: "16px" }}>
                <div className="flex justify-content-between align-items-center">
                    <div className="overflow-hidden">
                        <ConsultCardHeader consult={props.consult} maxWidth={"288px"} program={props.consult.program} />
                    </div>
                    <div
                        className="text-right text-sm"
                        style={{
                            minWidth: "150px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: "4px",
                        }}
                    >
                        {props.isP2 && <HollowChip label="P2" color={priority.textColor} />}
                        {props.consult.isHighPriority && <Badge value="Priority" className="priority-consult-badge" />}
                        {props.isOnHold && <HollowChip label="On Hold" color={priority.textColor} />}
                        {props.consult.isPediatric && <HollowChip label="Peds" color={priority.textColor} />}
                        {props.consult.isScheduled && <HollowChip label="Scheduled" color={priority.textColor} />}
                        {props.consult.reasonForVisit && <HollowChip label={getVisitReason(props.consult)} color={priority.textColor} />}

                        <span style={{ color: priority.textColor, marginLeft: "0.5rem" }}>{priority.text}</span>

                        {priority.value === ConsultCardPriorityEnum.Normal && (
                            <Equals style={{ color: "green", height: "16px", width: "16px" }} weight="bold" />
                        )}
                        {priority.value === ConsultCardPriorityEnum.High && (
                            <CaretUp style={{ color: priority.symbolColor, height: "16px", width: "16px" }} weight="bold" />
                        )}
                        {priority.value === ConsultCardPriorityEnum.Highest && (
                            <CaretDoubleUp style={{ color: priority.symbolColor, height: "16px", width: "16px" }} weight="bold" />
                        )}
                    </div>
                </div>
                <Divider />
                <div className="flex justify-content-between align-items-center">
                    <ConsultCardPatientInformation {...props} />
                    <div className="m-2 overflow-hidden">
                        <ConsultCardIcons {...createConsultCardIconsProps(props.consult)} />
                    </div>
                    <ConsultCardButton {...createConsultCardButtonProps(props.consult)} disabled={props.disabled} />
                </div>
            </Card>
        </div>
    );
};

export default ConsultCard;
